<template>
  <base-nav v-model="showMenu" class="navbar-absolute top-navbar" type="white" :transparent="true">
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline"><sidebar-toggle-button /></div>
      <div class="navbar-toggle d-inline" :class="{ toggled: $sidebar.showSidebar }">
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand">{{ routeName }}</a>
    </div>

    <template slot="logo">
      <div class="logo">
        <img v-if="logo64 && logo64 !== 'null' && logo64 !== 'undefined'" :src="'data:image/jpeg;base64, ' + logo64" alt="app-logo" />
      </div>
    </template>

    <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
      <div class="search-bar input-group" @click="searchModalVisible = true" v-if="false">
        <!--
          <input type="text" class="form-control" placeholder="Search...">
          <div class="input-group-addon"><i class="tim-icons icon-zoom-split"></i></div>
        -->
        <button class="btn btn-link" id="search-button" data-toggle="modal" data-target="#searchModal">
          <i class="tim-icons icon-zoom-split"></i>
        </button>
        <!-- You can choose types of search input -->
      </div>
      <modal :show.sync="searchModalVisible" class="modal-search" id="searchModal" :centered="false" :show-close="true">
        <input slot="header" v-model="searchQuery" type="text" class="form-control" id="inlineFormInputGroup" placeholder="SEARCH" />
      </modal>
      <base-dropdown tag="li" :menu-on-right="!$rtl.isRTL" title-tag="a" title-classes="nav-link" class="nav-item" v-if="false">
        <template slot="title">
          <div class="notification d-none d-lg-block d-xl-block"></div>
          <i class="tim-icons icon-sound-wave"></i>
          <p class="d-lg-none">New Notifications</p>
        </template>
        <li class="nav-link">
          <a href="#" class="nav-item dropdown-item">Mike John responded to your email</a>
        </li>
        <li class="nav-link">
          <a href="#" class="nav-item dropdown-item">You have 5 more tasks</a>
        </li>
        <li class="nav-link">
          <a href="#" class="nav-item dropdown-item">Your friend Michael is in town</a>
        </li>
        <li class="nav-link">
          <a href="#" class="nav-item dropdown-item">Another notification</a>
        </li>
        <li class="nav-link">
          <a href="#" class="nav-item dropdown-item">Another one</a>
        </li>
      </base-dropdown>
      <base-dropdown tag="li" :menu-on-right="!$rtl.isRTL" title-tag="a" class="nav-item" title-classes="nav-link" menu-classes="dropdown-navbar">
        <template slot="title" v-if="userObj">
          <p class="username">{{ userObj.username }}<i class="tim-icons icon-single-02"></i></p>
          <!-- <b class="caret d-none  d-xl-block"></b> -->
          <!-- <p class="d-lg-none">Log out</p> -->
        </template>
        <!-- <li class="nav-link">
          <a href="#" class="nav-item dropdown-item">Profile</a>
        </li> -->
        <!-- <li class="nav-link">
          <a href="/user" class="nav-item dropdown-item" >Settings</a>
        </li> -->
        <!-- <div class="dropdown-divider"></div> -->
        <li class="nav-link">
          <p class="nav-item dropdown-item" @click="userLogOut">Log out</p>
        </li>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import { BaseNav, Modal } from "@/components";
import SidebarToggleButton from "./SidebarToggleButton";
import { mapActions, mapGetters } from "vuex";
import router from "../../routes/router";

export default {
  components: {
    SidebarToggleButton,
    BaseNav,
    Modal
  },
  computed: {
    ...mapGetters({
      userObj: "user/getUserObj",
      logo64: "user/getUserLogo"
    }),
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: ""
    };
  },
  methods: {
    ...mapActions({
      logOut: "user/logout"
    }),
    startLoading() {
      this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)"
      });
    },
    stopLoading() {
      this.$loading().close();
    },
    async userLogOut() {
      this.startLoading();
      await this.logOut();
      this.stopLoading();
      router.push("/login");
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    }
  }
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
}
.username i {
  margin-left: 8px;
}
.logo {
  /* height: 60px; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: inherit;
}
.logo > img {
  max-width: 100%;
  height: 45px;
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
}
@media only screen and (max-width: 990px) {
  .logo {
    display: none;
    visibility: hidden;
  }
}
</style>

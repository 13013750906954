import { login, logout, refreshToken, getLastEvents, getLastCounters, getLastStats } from "../../services/api";

const state = {
  viewModes: ["map"],
  selectedGroupID: null,
  selectedDevicesIDs: [],
  selectedDateRange: [],
  sensorPackets: {},
  averagedSensorPackets: false,
  countersValues: [],
  statistics: [],
  availableDataTypesPackets: [],
  lastDataPacket: null,
};
// getters
const getters = {
  getSelectedGroupID: (state) => state.selectedGroupID,
  getSelectedDevicesIDs: (state) => state.selectedDevicesIDs,
  getSelectedGroupObj: (state, getters, rootState, rootGetters) =>
    rootGetters["user/getUserObj"] ? (rootGetters["user/getUserObj"].groups || []).find((gr) => gr._id === state.selectedGroupID) : null,
  getTotalSensors: (state, getters, rootState, rootGetters) =>
    rootGetters["user/getUserObj"]
      ? [].concat.apply(
          [],
          (rootGetters["user/getUserObj"].groups || []).map((gr) => gr.sensors)
        )
      : null,
  getSelectedDeviceObj: (state, getters, rootState, rootGetters) =>
    getters["getTotalSensors"] ? getters["getTotalSensors"].find((sn) => state.selectedDevicesIDs.includes(sn._id)) : null,
  getSelectedDateRange: (state) => state.selectedDateRange,
  getSensorPackets: (state) => state.sensorPackets,
  getLastSensorPacket: (state) => state.lastDataPacket,
  getCountersValues: (state) => state.countersValues,
  getAvailableDataTypes: (state) => state.availableDataTypesPackets,
  getStatistics: (state) => state.statistics,
  getPublicDataLink: (state, getters, rootState, rootGetters) => `https://public.gbscloud.eu/customers/${rootGetters["user/getUserObj"]._id}/groups/${state.selectedGroupID}`,
};

// actions
const actions = {
  setSelectedGroupID({ commit }, groupID) {
    commit("SELECT_GROUP", groupID);
  },
  setSelectedDevicesID({ commit }, devicesIDs) {
    commit("SELECT_DEVICES", devicesIDs);
  },
  setSelectedDateRange({ commit }, dateRange) {
    commit("SELECT_DATE_RANGE", dateRange);
  },

  async fetchSensorPackets({ commit, getters }, options = {}) {
    /* commit("SET_SENSOR_PACKETS", []);
    commit("SET_COUNTERS_VALUES", []);
    commit("SET_DATA_TYPE_AVAILABLE", []);
    commit("SET_STATS", []); */
    const selectedSensor = getters.getSelectedDeviceObj;
    const hourFormat = options.hourFormat;
    if (selectedSensor != null && selectedSensor != undefined) {
      if (selectedSensor.staticType && selectedSensor.staticType === "FTP-CAMERA") {
        console.log("Camera, doin nothing on Dashboard side!");
        commit("SET_SENSOR_PACKETS", []);
        commit("SET_AVERAGED_SENSOR_PACKETS", []);
        commit("SET_COUNTERS_VALUES", []);
        commit("SET_DATA_TYPE_AVAILABLE", []);
        return;
      }
      const inputs = selectedSensor.inputs;
      // inputShowable returns true the data type passed as an argument is platable (based on the input 'show' flag).
      const inputShowable = (dataType) => {
        const input = inputs.find((i) => i.destination == dataType) || inputs.find((i) => i.source == dataType && !i.destination);
        if (!input) {
          return false;
        }
        return input.show !== false;
      };

      const [events, counters, lastEvent] = await Promise.all([
        getLastEvents(selectedSensor.device, state.selectedDateRange[0], state.selectedDateRange[1], hourFormat || false),
        getLastCounters(selectedSensor.device, state.selectedDateRange[0], state.selectedDateRange[1], options.counterType),
        getLastEvents(selectedSensor.device, undefined, undefined, false, true),
      ]);
      commit("SET_SENSOR_PACKETS", events);
      commit("SET_AVERAGED_SENSOR_PACKETS", events.averageValues);
      commit("SET_COUNTERS_VALUES", counters);
      commit("SET_LAST_EVENT", lastEvent);

      let types = [];
      if (inputShowable("temperature") && events.temperatures.length > 0 && !types.includes("temperature")) {
        types.push("temperature");
      }
      if (inputShowable("temperature2") && events.temperatures2.length > 0 && !types.includes("temperature2")) {
        types.push("temperature2");
      }
      if (inputShowable("distance") && events.distances.length > 0 && !types.includes("distance")) {
        types.push("distance");
      }
      if (inputShowable("flowrate") && events.flowrates && events.flowrates.length > 0 && !types.includes("flowrate")) {
        types.push("flowrate");
      }
      if (inputShowable("flowrate2") && events.flowrates2 && events.flowrates2.length > 0 && !types.includes("flowrate2")) {
        types.push("flowrate2");
      }
      if (inputShowable("power") && events.powers.length > 0 && !types.includes("power")) {
        types.push("power");
      }
      if (inputShowable("power2") && events.powers2.length > 0 && !types.includes("power2")) {
        types.push("power2");
      }
      if (inputShowable("pulse1") && events.pulses1.length > 0 && !types.includes("pulse1")) {
        types.push("pulse1");
      }
      if (inputShowable("pulse2") && events.pulses2.length > 0 && !types.includes("pulse2")) {
        types.push("pulse2");
      }
      if (inputShowable("humidity") && events.humidities.length > 0 && !types.includes("humidity")) {
        types.push("humidity");
      }
      if (inputShowable("humidity2") && events.humidities2.length > 0 && !types.includes("humidity2")) {
        types.push("humidity2");
      }
      if (inputShowable("pressure") && events.pressures.length > 0 && !types.includes("pressure")) {
        types.push("pressure");
      }
      if (inputShowable("pressure2") && events.pressures2.length > 0 && !types.includes("pressure2")) {
        types.push("pressure2");
      }
      if (inputShowable("rain") && events.rains.length > 0 && !types.includes("rain")) {
        types.push("rain");
      }
      if (inputShowable("rain2") && events.rains2.length > 0 && !types.includes("rain2")) {
        types.push("rain2");
      }
      if (inputShowable("analog1") && events.analog1 && events.analog1.length > 0 && !types.includes("analog1")) {
        types.push("analog1");
      }
      if (inputShowable("analog2") && events.analog2 && events.analog2.length > 0 && !types.includes("analog2")) {
        types.push("analog2");
      }
      if (inputShowable("analog3") && events.analog3 && events.analog3.length > 0 && !types.includes("analog3")) {
        types.push("analog3");
      }
      if (inputShowable("analog4") && events.analog4 && events.analog4.length > 0 && !types.includes("analog4")) {
        types.push("analog4");
      }
      if (inputShowable("velocity") && events.velocities && events.velocities.length > 0 && !types.includes("velocity")) {
        types.push("velocity");
      }
      if (inputShowable("velocity2") && events.velocities2 && events.velocities2.length > 0 && !types.includes("velocity2")) {
        types.push("velocity2");
      }
      if (inputShowable("acceleration") && events.xAccs.length > 0 && !types.includes("acceleration")) {
        types.push("acceleration");
      }
      commit("SET_DATA_TYPE_AVAILABLE", types);
    }
  },
  async fetchStatistics({ commit, getters }, options = {}) {
    const selectedSensor = getters.getSelectedDeviceObj;
    const nextSlot = new Date(state.selectedDateRange[1].getTime() + 60 * 60000);
    if (selectedSensor != null && selectedSensor != undefined) {
      if (selectedSensor.staticType && selectedSensor.staticType === "FTP-CAMERA") {
        console.log("Camera, doin nothing on Dashboard side!");
        commit("SET_STATS", stats);
        return;
      }
      const stats = await getLastStats(selectedSensor.device, state.selectedDateRange[0], nextSlot);
      commit("SET_STATS", stats);
    }
  } /* ,
  async fetchCounters({ commit, getters }, options = {}) {
    const selectedSensor = getters.getSelectedDeviceObj;
    const nextSlot = new Date(state.selectedDateRange[1].getTime() + 60 * 60000);
    if (selectedSensor != null && selectedSensor != undefined) {
      const [stats] = await Promise.all([getLastStats(selectedSensor, state.selectedDateRange[0], nextSlot)]);
      commit("SET_COUNTERS_VALUES", counters);
    }
  } */,
};

// mutations
const mutations = {
  SELECT_GROUP: (state, groupID) => (state.selectedGroupID = groupID),
  SELECT_DEVICES: (state, devicesIDs) => (state.selectedDevicesIDs = devicesIDs || []),
  SELECT_DATE_RANGE: (state, dateRange) => (state.selectedDateRange = dateRange),
  SET_SENSOR_PACKETS: (state, packets) => (state.sensorPackets = packets || []),
  SET_STATS: (state, stats) => (state.statistics = stats || []),
  SET_AVERAGED_SENSOR_PACKETS: (state, averaged) => (state.averagedSensorPackets = averaged),
  SET_COUNTERS_VALUES: (state, countersValues) => (state.countersValues = countersValues || []),
  SET_STATISTICS: (state, stat) => (state.statistics = stat || []),
  SET_DATA_TYPE_AVAILABLE: (state, types) => (state.availableDataTypesPackets = types),
  SET_LAST_EVENT: (state, le) => (state.lastDataPacket = le),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

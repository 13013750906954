import Axios from "axios";
import { isTokenExpired } from "../helpers/tokenHelper";
import configModule from "../config";
import store from "../store";
import router from "../routes/router";

/* Se il token è scaduto lo refresho prima di inoltrare la richiesta */
export function refreshBeforeSend() {
  Axios.interceptors.request.use(
    config => {
      let originalRequest = config;
      const accessToken = localStorage.getItem("token");
      if (
        accessToken &&
        isTokenExpired(accessToken) && // Ho l'access token ed è scaduto
        (config.url !== configModule.API_ENDPOINT + "refresh_token" || // Non sto già refreshando (evito loop)
          (config.url === configModule.API_ENDPOINT + "refresh_token" && config.method === "delete")) // Tranne il caso di logout (metodo delete)
      ) {
        return store.dispatch("user/refreshToken").then(response => {
          localStorage.setItem("token", response.token);
          originalRequest.headers.Authorization = "Bearer " + response.token;
          Axios.defaults.headers.common["Authorization"] = "Bearer " + response.token;
          return Promise.resolve(originalRequest);
        });
      }
      return config;
    },
    err => {
      return Promise.reject(err);
    }
  );
}

/* Se il token è scaduto lo refresho prima di inoltrare la richiesta */
export function check401Error() {
  Axios.interceptors.response.use(
    response => {
      return response;
    },
    err => {
      // Se non sto refreshando il token oppure l'errore è proprio dovuto al refresh
      if (err.response && err.response.status === 401 && (store.getters["auth/isRefreshing"] === false || err.config.url === configModule.AUTH_ENDPOINT + "refresh_token")) {
        console.log("401 -> Login");
        localStorage.clear();
        router.push("/login");
      }
      return Promise.reject(err);
    }
  );
}

/* Se il server dà errore interno -> Logout */
export function check500Error() {
  Axios.interceptors.response.use(
    response => {
      return response;
    },
    err => {
      if (err.response && err.response.status === 500) {
        console.log("500 -> Login");
        localStorage.clear();
        router.push("/login");
      }
      return Promise.reject(err);
    }
  );
}

/* Setta Bearer Token */
export function setBearerToken() {
  const accessToken = localStorage.getItem("token");
  if (accessToken) {
    Axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  }
}

export function globalErrorHandling() {
  Axios.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      if (error.response && error.response.status != 401 && error.response.status != 403) {
        console.log("ERROR " + JSON.stringify(error.message));
      }
      throw new Error(error);
    }
  );
}

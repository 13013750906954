<template>
  <div class="fileinput text-center">
    <span class="btn btn-primary btn-file btn-sm btn-wide">
      <span class="fileinput-new">{{ csv ? changeText : selectText }}</span>
      <input type="hidden" value="" name="" />
      <input accept=".csv" @change="handleChange" type="file" name="..." class="valid" :multiple="false" aria-invalid="false" />
    </span>
    <!--     <base-button size="sm" :wide="true" :block="true" :simple="false" type="success" v-if="csv" @click="removeFile"> <i class="fas fa-times"></i> {{ removeText }} </base-button>
 -->
  </div>
</template>
<script>
export default {
  name: "csv-upload",
  props: {
    selectText: {
      type: String,
      default: "Carica .CSV",
    },
    changeText: {
      type: String,
      default: "Cambia",
    },
    removeText: {
      type: String,
      default: "Rimuovi",
    },
  },
  data() {
    return {
      csv: null,
    };
  },
  methods: {
    handleChange(event) {
      this.csv = true;
      this.$emit("change", event);
    },
    removeFile() {
      this.csv = false;
      this.$emit("change", null);
    },
  },
};
</script>
<style scoped>
.fileinput {
  width: 100% !important;
}
.btn {
  width: 100%;
}
</style>

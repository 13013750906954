import jwt_decode from "jwt-decode";

export function isTokenExpired(token) {
  let decoded = jwt_decode(token);

  var current_time = Date.now() / 1000;
  if (decoded.exp < current_time + 60) {
    return true;
  } else return false;
}

export function isAdminToken(token) {
  let decoded = jwt_decode(token);
  return decoded.role === "admin";
}

import VueRouter from "vue-router";
import routes from "./routes";
import { isAdminToken } from "../helpers/tokenHelper";

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: "active",
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

function isLoggedIn() {
  return localStorage.getItem("token") && localStorage.getItem("refresh_token");
}

function isAdmin() {
  const token = localStorage.getItem("token");
  return token && isAdminToken(token);
}

router.beforeEach((to, from, next) => {
  if ((!isLoggedIn() || !isAdmin()) && to.matched.some((record) => record.meta.requiresAdmin)) {
    next({
      name: "Home",
    });
  } else if (isAdmin() && to.name !== "Customers" && to.name !== "Sensors") {
    next({
      name: "Admin",
    });
  } else if (!isLoggedIn() && to.matched.some((record) => record.meta.requiresAuth)) {
    next({
      name: "Login",
    });
  } else if (to.name === "login" && isLoggedIn()) {
    // Redirect user to homepage
    return next({
      name: "Home",
    });
  } else {
    // In base alla route -> inject di struttura e sensore selezionato
    next(to.params);
  }
});

export default router;

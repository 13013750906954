/*!

 =========================================================
 * Vue Black Dashboard PRO - v1.3.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-black-dashboard-pro
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";
import RouterPrefetch from "vue-router-prefetch";
import DashboardPlugin from "./plugins/dashboard-plugin";
import App from "./App.vue";
import { check500Error, refreshBeforeSend, check401Error, setBearerToken, globalErrorHandling } from "./services/interceptors";
// router setup
import router from "./routes/router";
import i18n from "./i18n";
import "./registerServiceWorker";
import { Loading } from "element-ui";
/* import VueChartJs from "vue-chartjs"; */

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
//Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;

check500Error();
refreshBeforeSend();
check401Error();
//globalErrorHandling();
setBearerToken();
/* eslint-disable no-new */

import { required, email, confirmed, regex, min, numeric, min_value, max_value, length, integer } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("min", min);
extend("email", email);
extend("required", { ...required, message: () => i18n.t("validation.requiredField") });
extend("regex", regex);
extend("confirmed", confirmed);
extend("numeric", numeric);
extend("min_value", min_value);
extend("max_value", max_value);
extend("length", length);
extend("integer", integer);

import _ from 'lodash';
Object.defineProperty(Vue.prototype, '$_', { value: _ });

new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  store,
  i18n,
});

/* var vm = new Vue({
  el: ".app"
}); */

<template>
  <div class="sidebar" :data="backgroundColor">
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <div class="logo">
        <a class="simple-text logo-mini">
          <img :src="logo" alt="gbs-logo" />
        </a>
        <a class="simple-text logo-normal">
          {{ title }}
        </a>
      </div>
      <slot></slot>
      <ul class="nav">
        <slot name="links">
          <sidebar-item v-for="(link, index) in sidebarLinks" :key="link.name + index" :link="link">
            <sidebar-item v-for="(subLink, index) in link.children" :key="subLink.name + index" :link="subLink"> </sidebar-item>
          </sidebar-item>
        </slot>
      </ul>
      <ul class="lang">
        <li :style="{ color: lang === 'it-IT' ? '' : 'var(--info)' }" :class="[lang === 'it-IT' ? 'lang-selected' : '']" @click="switchLang('it-IT')">Italiano</li>
        <li :style="{ color: lang === 'en' ? '' : 'var(--info)' }" :class="[lang === 'en' ? 'lang-selected' : '']" @click="switchLang('en')">English</li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "sidebar",
  props: {
    title: {
      type: String,
      default: "Creative Tim",
      description: "Sidebar title"
    },
    shortTitle: {
      type: String,
      default: "CT",
      description: "Sidebar short title"
    },
    logo: {
      type: String,
      default: "v2/img/gbs.svg",
      description: "Sidebar app logo"
    },
    backgroundColor: {
      type: String,
      default: "vue",
      validator: value => {
        let acceptedValues = ["", "vue", "blue", "green", "orange", "red", "primary"];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: "Sidebar background color (vue|blue|green|orange|red|primary)"
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description: "List of sidebar links as an array if you don't want to use components for these."
    },
    autoClose: {
      type: Boolean,
      default: true,
      description: "Whether sidebar should autoclose on mobile when clicking an item"
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    }
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    switchLang(lang) {
      this.$i18n.locale = lang;
    }
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  }
};
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
li {
  list-style: none;
}
.lang {
  padding: 0;
  padding-left: 12px;
  margin-bottom: 12px;
  position: absolute;
  bottom: 0;
}
.lang > li {
  cursor: pointer;
  font-weight: 400;
}
.lang-selected {
  font-weight: 800 !important;
}
</style>
